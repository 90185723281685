import styled, { css } from 'styled-components';
import { theme } from '@theme';

export const StyledTextBackground = styled.div`
    display: block;
    padding: 20px 16px;
    /* box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25); */
    /* border-radius: 50px; */
    font-size: 1.6rem;
    background-color: ${props => props.$backgroundColor || '#000000'};
    color: ${props => props.$textColor || 'white'};
    text-align: center;
    font-weight: 900;
    /* min-width: 200px;
    width: 80%;
    max-width: 350px; */
    /* margin: 0 auto 25px auto; */

    ${theme.mediaQuerys.smUp} {
        font-size: 1.8rem;
        /* margin-left: 0; */
        /* ${props => props.$align === 'left' || !props.$align ? css`
            margin-left: 0;
        `: props.$align === 'right' ? css`
            margin-right: 0;
        `: null} */
        /* width: 100%; */
        /* max-width: 400px; */
    }

    ${theme.mediaQuerys.mdUp} {
        /* margin-bottom: 30px; */
        padding: 14px 20px;
        font-size: 2rem;
        /* max-width: 400px; */
    }

    > :last-child { // last item shouldn't have margin
        margin-bottom: 0 !important;
    }
`