import React from 'react'
import { StyledTextBackground } from './styles'

import HtmlParser from '@commons/html-parser';

export default function TextBackground ({ 
    backgroundColor, 
    textColor, 
    alignFlag, 
    text,
    content
 }) {
    return (
        <StyledTextBackground
            className="wysiwyg-spacer"
            $backgroundColor={backgroundColor}
            $textColor={textColor}
            // dangerouslySetInnerHTML={{ __html: text }}
        >
            <HtmlParser>
                {content || text}
            </HtmlParser>
        </StyledTextBackground>
    )
}
